import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import parse from "html-react-parser"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ClientOnly from "../components/client-only"
import Search from "../components/search"

import config from "../../config"

const postMeta = [
  { name: "og:image:width", content: "80px" },
  { name: "og:image:height", content: "80px" },
]

const imageSrc = `${config.SITE_META_URL}/${config.MANIFEST_ICON.replace("static/", "")}`

const BlogIndex = ({
  location,
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <SEO
          meta={postMeta}
          title={config.SITE_META_TITLE}
          description={config.SITE_META_DESCRIPTION}
          url={location.href}
          imageSrc={imageSrc}
        />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout locationHref={location.href} isHomePage>
      <SEO
        meta={postMeta}
        title="Home"
        description={config.SITE_META_DESCRIPTION}
        url={location.href}
        imageSrc={imageSrc}
      />
      <Bio />
      <ClientOnly>
        <Search />
      </ClientOnly>
      <div className="blog-index">
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.title
            const featuredImage =
              post.featuredImage?.node?.localFile?.childImageSharp?.fluid
            const altText = post?.featuredImage?.node?.altText

            return (
              <li key={post.uri}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2 className="blog-post-title">
                      <Link to={post.uri} itemProp="url">
                        <span itemProp="headline">{parse(title)}</span>
                      </Link>
                    </h2>
                    <small>{post.date}</small>
                  </header>
                  <Link to={post.uri} itemProp="url">
                    <Img fluid={featuredImage} altText={altText} />
                  </Link>
                  <section itemProp="description">
                    {parse(post.excerpt)}
                    <Link to={post.uri} itemProp="url">
                      Read More...
                    </Link>
                  </section>
                </article>
                <hr />
              </li>
            )
          })}
        </ol>
        <div className="blog-index-nav">
          {previousPagePath && (
            <>
              <span>←</span> <Link to={previousPagePath}>Previous page</Link>
            </>
          )}
          {nextPagePath && (
            <>
              {" "}
              <span>-</span> <Link to={nextPagePath}>Next page</Link>
            </>
          )}{" "}
          {nextPagePath && <span>→</span>}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
