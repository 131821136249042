import React from "react"
import { Helmet } from "react-helmet"
import config from '../../config'

const googleSearchEngineId = config.GOOGLE_SEARCH_ENGINE_ID

const search = () => {
  if (googleSearchEngineId) {
    const googleProgrammableSearchUrl = `https://cse.google.com/cse.js?cx=${googleSearchEngineId}`

    return (
      <div>
        <Helmet>
          {" "}
          <script async src={googleProgrammableSearchUrl}></script>{" "}
        </Helmet>
        <div className="gcse-search"></div>
      </div>
    )
  } else {
    return null
  }
}
export default search
